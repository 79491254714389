import React, { useState } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import LoginForm from "../component/Auth/Login/Form"
import config from "../config"

const captchaKey = config.RECAPTCHA_SITE_KEY

export default function Login() {
  const [visibleLoginDiv, setVisibleLoginDiv] = useState("DeskLoginDiv")

  const DeskLogin = () => {
    setVisibleLoginDiv("DeskLoginDiv")
  }
  const AppLogin = () => {
    setVisibleLoginDiv("AppLoginDiv")
  }

  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
        <LoginForm
          AppLogin={AppLogin}
          visibleLoginDiv={visibleLoginDiv}
          DeskLogin={DeskLogin}
        />
      </GoogleReCaptchaProvider>
    </>
  )
}
