import React, { useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import { forgetPassword } from "../api/user"
import logo from "../assets/image/logo.svg"
import { toast } from "../lib/toast"
import config from "../config"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import isEmpty from "is-empty"

const captchaKey = config.RECAPTCHA_SITE_KEY
export default function ForgotPassword(props) {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [email, setEmail] = useState("")

  const [validateError, setValidateError] = useState({})

  const dispatch = useDispatch()

  const handleChange = e => {
    setEmail(e.target.value)
  }
  const handleReCaptcha = async () => {
    try {
      if (!executeRecaptcha) {
        toast(
          {
            message: "Recaptcha required",
            type: "error",
          },
          dispatch,
        )
        return ""
      }
      return await executeRecaptcha("login")
    } catch (err) {
      toast(
        {
          message: "Invalid recaptcha",
          type: "error",
        },
        dispatch,
      )
      return ""
    }
  }

  const forgotPasswordApi = async () => {
    try {
      const captcha = await handleReCaptcha()
      if (isEmpty(captcha)) {
        // setLoader(false)
        toast(
          {
            message: "Invalid recaptcha",
            type: "error",
          },
          dispatch,
        )
        return
      }
      const { success, message, errors } = await forgetPassword({
        email,
        reCaptcha: captcha,
      })
      if (success) {
        setValidateError("")
        toast(
          {
            message,
            type: "success",
          },
          dispatch,
        )
        setEmail("")
      } else {
        if (errors) {
          setValidateError(errors)
        } else {
          toast(
            {
              message,
              type: "error",
            },
            dispatch,
          )
        }
      }
    } catch (error) {
      toast(
        {
          message: "Something went wrong",
          type: "error",
        },
        dispatch,
      )
    }
  }

  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
        <div className="auth_wrapper">
          <Row>
            <Col xl={6} lg={6} md={6} className="">
              <img
                src={require("../assets/image/logpage.png")}
                alt="Login"
                className="img-fluid logpage"
              />
            </Col>
            <Col xl={6} lg={6} md={6} className="mx-auto">
              <img src={logo} alt="Logo" className="logo_img" />
              <div className="auth_bg ">
                <div className="auth_header auth_header_flex">
                  <h2>Forgot Password</h2>
                </div>

                <Form className="auth_form">
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    value={email}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{validateError?.email || ""}</p>

                  <div className="mt-3">
                    <button
                      className="primary-btn w-100"
                      type="button"
                      onClick={forgotPasswordApi}
                    >
                      Submit
                      <img
                        src={require("../assets/image/btn-arrow.png")}
                        className="img-fluid"
                      />
                    </button>
                  </div>
                </Form>
                <div className="copyright">
                  <p>Copyright © 2024 Neco Smart. All rights reserved.</p>
                  <p>Privacy Policy | Terms of Service</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </GoogleReCaptchaProvider>
    </>
  )
}
