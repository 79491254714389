import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import RegisterForm from "../component/Auth/Register/Form"
import config from "../config"
const captchaKey = config.RECAPTCHA_SITE_KEY
export default function Register() {
  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
        <RegisterForm />
      </GoogleReCaptchaProvider>
    </>
  )
}
