let config = {
  API_HOST: "",
  API_URL: "",
  NAME: "Neco Smart",
  FRONTEND: "",
  RECAPTCHA_SITE_KEY: "",
  RECAPTCHA_SECRET_KEY: "",
}

if (process.env.REACT_APP_MODE === "production") {
  config = {
    API_HOST: "",
    API_URL: "",
    NAME: "Neco Smart",
    FRONTEND: "",
    CRYPTO_SECRET_KEY: "xTtLdGeFAQ729WrmMBdG9Gc5ZWb8P7",
    RECAPTCHA_SITE_KEY: "",
    RECAPTCHA_SECRET_KEY: "",
  }
} else if (process.env.REACT_APP_MODE === "demo") {
  config = {
    API_HOST: "https://necoapi.wearedev.team",
    API_URL: "https://necoapi.wearedev.team/api",
    NAME: "Neco Smart",
    FRONTEND: "https://neco-demo-frontend-2024.pages.dev",
    CRYPTO_SECRET_KEY: "xTtLdGeFAQ729WrmMBdG9Gc5ZWb8P7",
    RECAPTCHA_SITE_KEY: "6Lf3V4oqAAAAADPKpXmdqmFzKkp4KZQ0YdFw5ScQ",
    RECAPTCHA_SECRET_KEY: "6Lf3V4oqAAAAAGGUgR-w5h8h4qZNw5_2oXy3Iev-",
  }
} else {
  config = {
    API_HOST: "http://localhost:2053",
    API_URL: "http://localhost:2053/api",
    NAME: "Neco Smart",
    FRONTEND: "http://localhost:3000",
    CRYPTO_SECRET_KEY: "xTtLdGeFAQ729WrmMBdG9Gc5ZWb8P7",
    RECAPTCHA_SITE_KEY: "6Lf3V4oqAAAAADPKpXmdqmFzKkp4KZQ0YdFw5ScQ",
    RECAPTCHA_SECRET_KEY: "6Lf3V4oqAAAAAGGUgR-w5h8h4qZNw5_2oXy3Iev-",
  }
}

export default config
